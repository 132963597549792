/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import DashLayout from "../components/DashLayout"
import StyledHero from "../components/StyledHero"
import Billing from "../components/Home/Dashboard/Billing"
import { graphql } from "gatsby"
const billing = ({ data }) => {
  return (
    <DashLayout>
      <StyledHero img="none">
        < Billing />
      </StyledHero>
    </DashLayout>
  )
}

export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default billing
