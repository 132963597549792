/* eslint-disable */
import React from "react"
import Title from "../../Title"
import styles from "../../../css/billing.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
const Billing = () => {

  return (
    <section className={styles.billing}>
      <span className={styles.modalHeader}><Title title="Rivet" subtitle="Billing" /></span>
      <div className={styles.center}>
        <section className={styles.row1}>
        <article className={styles.keyBox}>
          <h4>My Keys</h4>
          <ul className={styles.boxContent}>
            <li>K1: e1c41ee06297468bb03bbee405649cab</li>
            <li>K2: PurityDivertAnkleMousePigResembleListBelieveApproveNuclearWindowSpeak</li>
          </ul>
        </article>
        </section>
        <section className={styles.row2}>
        <article className={styles.bulletinBox}>
          <h4>Bulletins</h4>
          <ul className={styles.boxContent}>
            <li>Stuff goes here</li>
            <li>More stuff</li>
          </ul>
        </article>
        <article className={styles.accountBox}>
        <h4>Plan Details</h4>
          <ul className={styles.boxContent}>
            <li>Current Plan: BUIDL</li>
            <li>Active Rate: Free</li>
            <li>Usage: 110,101</li>
            <li>Tier Max: 3,000,000</li>
            <li>Days Left in Cycle: 20</li>
            <li>Metered Cost: $0.00</li>
            <li>Last Invoice: April 2019 </li>
          </ul>
        </article>
        </section>
      </div>
    </section>
  )
}

export default Billing
